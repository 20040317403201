export const ServiceUrl = {
  V2_LOGIN: {
    CHECK_USERNAME: "v2/login/check-username",
    CAPTCHA: "v1/captcha",
    VERIFY_CAPTCHA: "v2/login/verify-captcha",
    UPDATE_PASSWORD: "v2/login/update-pass",
  },

  LOGIN_V2: {
    CHECK: "/v2/admin/check",
    CHECK_USERNAME: "/v1/admin/login",
  },
  COURSES: {
    CATEGORY_CREATE: "v1/admin/course/category/create",
    CATEGORY_LIST: "v1/admin/course/category/list",
    CATEGORY_DELETE: "v1/admin/course/category/delete",
    CATEGORY_UPDATE: "v1/admin/course/category/update",

    LEVEL_CREATE: "v1/admin/course/level/create",
    LEVEL_LIST: "v1/admin/course/level/list",
    LEVEL_DELETE: "v1/admin/course/level/delete",
    LEVEL_UPDATE: "v1/admin/course/level/update",

    DEGREE_CREATE: "v1/admin/course/degree/create",
    DEGREE_LIST: "v1/admin/course/degree/list",
    DEGREE_DELETE: "v1/admin/course/degree/delete",
    DEGREE_UPDATE: "v1/admin/course/degree/update",

    DEPARTMENT_CREATE: "v1/admin/course/dept/create",
    DEPARTMENT_LIST: "v1/admin/course/dept/list",
    DEPARTMENT_DELETE: "v1/admin/course/dept/delete",
    DEPARTMENT_UPDATE: "v1/admin/course/dept/update",

    COURSE_CREATE: "v1/admin/course/create",
    COURSE_LIST: "v1/admin/course/list",
    COURSE_LIST_MININFO: "v1/admin/course/min-info",
    COURSE_DELETE: "v1/admin/course/delete",
    COURSE_UPDATE_BASIC: "v1/admin/course/update-basic",
    COURSE_UPDATE_CONTENT: "v1/admin/course/update-content",

    ATT_PERCENTAGE_SAVE: "v1/admin/course/save-att-percentage",
    ATT_PERCENTAGE_LIST: "v1/admin/course/list-att-percentage",
    ATT_PERCENTAGE_DELETE: "v1/admin/course/delete-att-percentage",
    ATT_PERCENTAGE_UPDATE: "v1/admin/course/update-att-percentage",
  },
  COLLEGES: {
    COUNTRY_CREATE: "v1/admin/country/create",
    COUNTRY_LIST: "v1/admin/country/list",
    COUNTRY_DELETE: "v1/admin/country/delete",
    COUNTRY_UPDATE: "v1/admin/country/update",

    STATE_CREATE: "v1/admin/state/create",
    STATE_LIST: "v1/admin/state/list",
    STATE_DELETE: "v1/admin/state/delete",
    STATE_UPDATE: "v1/admin/state/update",

    DISTRICT_CREATE: "v1/admin/district/create",
    DISTRICT_LIST: "v1/admin/district/list",
    DISTRICT_DELETE: "v1/admin/district/delete",
    DISTRICT_UPDATE: "v1/admin/district/update",

    CITY_CREATE: "v1/admin/city/create",
    CITY_LIST: "v1/admin/city/list",
    CITY_DELETE: "v1/admin/city/delete",
    CITY_UPDATE: "v1/admin/city/update",

    TYPE_CREATE: "v1/admin/college/type/create",
    TYPE_LIST: "v1/admin/college/type/list",
    TYPE_DELETE: "v1/admin/college/type/delete",
    TYPE_UPDATE: "v1/admin/college/type/update",

    COLLEGE_CREATE: "v1/admin/college/create",
    COLLEGE_LIST: "v1/admin/college/list",
    COLLEGE_DELETE: "v1/admin/college/delete",
    COLLEGE_UPDATE_BASIC: "v1/admin/college/update-basic",
    COLLEGE_UPDATE_CONTENT: "v1/admin/college/update-content",

    COLLEGE_ADD_COURSE: "v1/admin/college/add-course",
    COLLEGE_LIST_COURSES: "v1/admin/college/list-courses",
    COLLEGE_LIST_COURSES_BATCH_WISE: "v1/admin/college/list-courses-batch-wise",
    COLLEGE_DELETE_COURSE: "v1/admin/college/delete-course",
    COLLEGE_UPDATE_COURSE: "v1/admin/college/update-course",
    COLLEGE_UPDATE_IMAGE: "v1/admin/college/update-image",

    COLLEGE_LIST_CONTACTS: "v1/admin/college/contacts",
    COLLEGE_DELETE_CONTACTs: "v1/admin/college/delete-contact",
    COLLEGE_UPDATE_CONTACTS: "v1/admin/college/update-contacts",

    ORDER_DATA: "college/get_order",
  },
  DISTANCE_OLD: {
    IMPORT_DISTANCE_STUDENTS:
      "v1/admin/distanceold_import/import-distance-students",
    IMPORT_DISTANCE_SUBJECTS:
      "v1/admin/distanceold_import/import-distance-subjects",
    IMPORT_DISTANCE_RESULTS:
      "v1/admin/distanceold_import/import-distance-results",

    DISTANCE_RESULT_BATCH: "v1/admin/distanceold_grade/distance-result-batch",
    DISTANCE_GRADE_GENERATE:
      "v1/admin/distanceold_grade/distance-grade-generate",

    DISTANCE_CGP_GENERATE: "v1/admin/distanceold_grade/distance-cgp-generate",
    DISTANCE_SUMCG_GENERATE:
      "v1/admin/distanceold_grade/distance-sumcg-generate",

    LIST_BY_STUDENTS: "v1/admin/distanceold_marksheet/list",
    UPDATE_STUDENTS: "v1/admin/distanceold_marksheet/update",
    DELETE_STUDENTS: "v1/admin/distanceold_marksheet/delete",

    LIST_BY_SUBJECTS: "v1/admin/distanceold_subjects/list-subjects",
    UPDATE_SUBJECTS: "v1/admin/distanceold_subjects/update-subjects",

    LIST_BY_RESULTS: "v1/admin/distanceold_results/student-results",

    EXAM_MOTNS_LIST: "v1/admin/distanceold_marksheet/exam-months",
    MARKSHEET_BY_REGNO: "v1/admin/distanceold_marksheet/marksheet",
  },
  ADMISSION: {
    SETTINGS_MENU: "v1/admin/settings/menu-list",
    SETTINGS: "v1/settings",
    LIST_VARIABLE_SETTINGS: "v1/admin/settings/list-settings",
    UPDATE_VARIABLE_SETTINGS: "v1/admin/settings/update-settings",
    ADD_VARIABLE_SETTINGS: "v1/admin/settings/add-settings",

    LIST_SMS_TEMPLATES: "v1/admin/smstemplate/list-templates",
    UPDATE_SMS_TEMPLATES: "v1/admin/smstemplate/update-template",
    ADD_SMS_TEMPLATES: "v1/admin/smstemplate/add-template",
    DELETE_SMS_TEMPLATES: "v1/admin/smstemplate/delete-template",

    LIST_MAIL_TEMPLATES: "v1/admin/mailtemplate/list-templates",
    UPDATE_MAIL_TEMPLATES: "v1/admin/mailtemplate/update-template",
    ADD_MAIL_TEMPLATES: "v1/admin/mailtemplate/add-template",
    DELETE_MAIL_TEMPLATES: "v1/admin/mailtemplate/delete-template",

    USER_PROFILE: "v1/admin/users/my-profile",
    USER_CHANGE_PASSWORD: "v1/admin/users/change-password",

    NEW_USER_LOGIN: "v1/admin/users/save-user",
    LIST_USERS_LOGIN: "v1/admin/users/list-users",
    USER_STATUS_CHANGE: "v1/admin/users/status-change",
    REMOVE_USER_LOGIN: "v1/admin/users/remove-user",
    UPDATE_USER_LOGIN: "v1/admin/users/update-user",
    PASS_USER_LOGIN: "v1/admin/users/user-password",
    UPDATE_USER_PASSWORD: "v1/admin/users/update-user-password",
  },

  SETTINGS: {
    ACADEMIC_YEAR_CREATE: "v1/admin/settings/acyear/create",
    ACADEMIC_YEAR_LIST: "v1/admin/settings/acyear/list",
    ACADEMIC_YEAR_DELETE: "v1/admin/settings/acyear/delete",
    ACADEMIC_YEAR_UPDATE: "v1/admin/settings/acyear/update",

    COMMUNITY_CREATE: "v1/admin/settings/community/create",
    COMMUNITY_LIST: "v1/admin/settings/community/list",
    COMMUNITY_DELETE: "v1/admin/settings/community/delete",
    COMMUNITY_UPDATE: "v1/admin/settings/community/update",

    BLOOD_GROUP_CREATE: "v1/admin/settings/bgroup/create",
    BLOOD_GROUP_LIST: "v1/admin/settings/bgroup/list",
    BLOOD_GROUP_DELETE: "v1/admin/settings/bgroup/delete",
    BLOOD_GROUP_UPDATE: "v1/admin/settings/bgroup/update",

    RELIGION_CREATE: "v1/admin/settings/religion/create",
    RELIGION_LIST: "v1/admin/settings/religion/list",
    RELIGION_DELETE: "v1/admin/settings/religion/delete",
    RELIGION_UPDATE: "v1/admin/settings/religion/update",

    MOTHER_TONGUE_CREATE: "v1/admin/settings/mothertongue/create",
    MOTHER_TONGUE_LIST: "v1/admin/settings/mothertongue/list",
    MOTHER_TONGUE_DELETE: "v1/admin/settings/mothertongue/delete",
    MOTHER_TONGUE_UPDATE: "v1/admin/settings/mothertongue/update",

    BATCH_CREATE: "v1/admin/settings/batch/create",
    BATCH_LIST: "v1/admin/settings/batch/list",
    BATCH_DELETE: "v1/admin/settings/batch/delete",
    BATCH_UPDATE: "v1/admin/settings/batch/update",

    SAVE_CASHBOOK: "v1/admin/settings/cashbook/create",
    LIST_CASHBOOK: "v1/admin/settings/cashbook/list",
    DELETE_CASHBOOK: "v1/admin/settings/cashbook/delete",
    UPDATE_CASHBOOK: "v1/admin/settings/cashbook/update",

    USER_LOGIN_CREATE: "v1/admin/settings/user-login/create",
    USER_LOGIN_LIST: "v1/admin/settings/user-login/list",
    USER_LOGIN_DELETE: "v1/admin/settings/user-login/delete",
    USER_LOGIN_UPDATE: "v1/admin/settings/user-login/update",

    UPDATE_EMPLOYEE: "v1/admin/settings/user-login/update-employee",
    CREATE_EMPLOYEE: "v1/admin/settings/user-login/create-employee",

    SAVE_USERROLE: "v1/admin/rbac/userrole/save",
    LIST_USERROLE: "v1/admin/rbac/userrole/list",
    DELETE_USERROLE: "v1/admin/rbac/userrole/delete",
    UPDATE_USERROLE: "v1/admin/rbac/userrole/update",

    SAVE_ROLE_MODULE: "v1/admin/rbac/roleaccess/save-module",
    DELETE_ROLE_MODULE: "v1/admin/rbac/roleaccess/delete-module",
    UPDATE_ROLE_MODULE: "v1/admin/rbac/roleaccess/update-module-access",
    UPDATE_USER_ACCESS: "v1/admin/rbac/roleaccess/update-user-access",
    LIST_ROLE_ACCESS: "v1/admin/rbac/roleaccess/access-list",
    UPDATE_ROLE_ACCESS: "v1/admin/rbac/roleaccess/update-role-access",
    LIST_ROLE_PERMISSIONS: "v1/admin/rbac/roleaccess/list-role-permissions",

    LIST_COMMUNITY: "v1/set/community",
    LIST_RELIGIONS: "v1/set/religions",

    LIST_USER_LOGS: "v1/admin/logs/list-all",

    SAVE_GRADE_POINTS: "v1/admin/settings/gradePoint/save",
    LIST_GRADE_POINTS: "v1/admin/settings/gradePoint/list",
    UPDATE_GRADE_POINTS: "v1/admin/settings/gradePoint/update",
    DELETE_GRADE_POINTS: "v1/admin/settings/gradePoint/delete",

    PHD_BATCH_CREATE: "v1/admin/settings/batch/create-phd-batch",
    PHD_BATCH_LIST: "v1/admin/settings/batch/list-phd-batch",
    PHD_BATCH_DELETE: "v1/admin/settings/batch/delete-phd-batch",
    PHD_BATCH_UPDATE: "v1/admin/settings/batch/update-phd-batch",

    LIST_PHD_TASKS: "v1/admin/settings/tasks/list",
    CREATE_PHD_TASKS: "v1/admin/settings/tasks/create",
    UPDATE_PHD_TASKS: "v1/admin/settings/tasks/update",
    DELETE_PHD_TASKS: "v1/admin/settings/tasks/delete",

    LIST_MODE_OF_STUDY: "v1/admin/settings/mos/list",
    CREATE_MODE_OF_STUDY: "v1/admin/settings/mos/create",
    UPDATE_MODE_OF_STUDY: "v1/admin/settings/mos/update",
    DELETE_MODE_OF_STUDY: "v1/admin/settings/mos/delete",
  },

  FIELD_SETTINGS: {
    NEW: "v1/field_settings/new",
    LIST: "v1/field_settings/all",
    REMOVE: "v1/field_settings/delete",
    UPDATE: "v1/field_settings/update",
  },
  SMS: {
    CHECK_BALANCE: "v1/admin/sms/balance",
  },
  STUDENTS: {
    CREATE_STUDENT: "v1/admin/students/create",
    LIST_STUDENTS: "v1/admin/students/list",
    LIST_AUTONOMOUS_STUDENTS: "v1/admin/students/list-autonomous-students",
    UPDATE_STUDENT: "v1/admin/students/update",
    UPDATE_LEFT_LONG_ABSENT: "v1/admin/students/update-left-long-absent",
    REMOVE_LONG_ABSENT: "v1/admin/students/remove-long-absent",
    QUICK_EDIT: "v1/admin/students/quick-update",

    IMPORT_STUDENTS: "v1/admin/students/import",
    PROMOTE_STUDENTS: "v1/admin/students/promote",
    UPDATE_STUDENT_REGISTER_NO: "v1/admin/students/update-register-no",
    UPDATE_PROGRAM_WISE_SUBJECTS:
      "v1/admin/students/update-programwise-subjects",
    ALLOCATED_STUDENT_SUBJECTS: "v1/admin/students/students-subjects",
    ALLOCATION_LIST: "v1/admin/students/allocation-list",
    ALLOCATE_ARREAR_SUBJECTS: "v1/admin/students/allocate-arrear-subjects",
    SAVE_STUDENT_SUBJECTS: "v1/admin/students/save-student-subjects",
    DELETE_STUDENT_SUBJECT: "v1/admin/students/delete-student-subject",

    IMPORT_STUDENT_ATTENDANCE: "v1/admin/student/att/import",
    GET_STUDENT_ATTENDANCE_REPORT: "v1/admin/student/att/get",
    CHECK_STUDENT_ATTENDANCE: "v1/admin/student/att/check-student-attendance",
    SAVE_STUDENT_ATTENDANCE: "v1/admin/student/att/save-student-attendance",
    UPDATE_STUDENT_ATTENDANCE: "v1/admin/student/att/update-student-attendance",
    LIST_ATTENDANCE_RULES: "v1/admin/student/att/list-attendance-rules",
    GET_STUDENT_STRENGTH_REPORT: "v1/admin/student/report/get",

    LENGTH_EXAM_STUDENTS_COUNT: "v1/admin/students/length-exam-students-count",
    GET_EXAM_STUDENTS_COUNT: "v1/admin/students/exam-students-count",
    VIEW_SUBJECT_STUDENTS: "v1/admin/students/view-subject-students",

    SAVE_PROVISIONAL_CERTIFICATE: "v1/admin/student/provisional/save",
    LIST_PROVISIONAL_CERTIFICATES: "v1/admin/student/provisional/list-all",
    PROVISIONAL_ELIGIBILITY_COUNT:
      "v1/admin/student/provisional/provisional-eligibility-count",
    CHECK_PROVISIONAL_ELIGIBILITY:
      "v1/admin/student/provisional/check-provisional-eligibility",
    GET_GOLD_MEDALISTS: "v1/admin/student/provisional/get-gold-medalists",
    AUTO_PROVISIONAL_ELIGIBILITY_COUNT:
      "v1/admin/student/provisional/auto-provisional-eligibility-count",
    CHECK_AUTO_PROVISIONAL_ELIGIBILITY:
      "v1/admin/student/provisional/check-auto-provisional-eligibility",
    GET_PROVISIONAL_CERT:
      "v1/admin/student/provisional/get-provisional-certificate",
    GET_PROVISIONAL_CERT_AUTO:
      "v1/admin/student/provisional/get-provisional-certificate-auto",
    SAVE_AUTONOMOUS_CGPA: "v1/admin/student/provisional/save-autonomous-cgpa",
    SAVE_PROVISIONAL_LOG: "v1/admin/student/provisional/save-provisional-log",

    LIST_FEE_TEMPLATES: "v1/admin/student/bonaifed/list-fees",
    DELETE_BONAFIED_CERTIFICATE: "v1/admin/student/bonaifed/delete",
  },

  PHD_STUDENTS: {
    CREATE_STUDENT: "v1/admin/phd-students/create",
    LIST_STUDENTS: "v1/admin/phd-students/list",
    LIST_AUTONOMOUS_STUDENTS: "v1/admin/phd-students/list-autonomous-students",
    UPDATE_STUDENT: "v1/admin/phd-students/update",
    UPDATE_LEFT_LONG_ABSENT: "v1/admin/phd-students/update-left-long-absent",
    REMOVE_LONG_ABSENT: "v1/admin/phd-students/remove-long-absent",
    QUICK_EDIT: "v1/admin/phd-students/quick-update",

    UPDATE_DEPARTMENT_WISE_TASKS:
      "v1/admin/phd-students/update-departmentwise-tasks",
    SAVE_STUDENT_TASKS: "v1/admin/phd-students/save-student-tasks",
    ALLOCATION_LIST: "v1/admin/phd-students/task-allocation-list",
    DELETE_STUDENT_TASK: "v1/admin/phd-students/delete-student-task",
    ALLOCATED_STUDENT_TASKS: "v1/admin/phd-students/students-tasks",

    LIST_ELIGIBLE_PROMOTION_STUDENTS:
      "v1/admin/phd-students/eligible-promotion-students",
    PROMOTE_PHD_STUDENT: "v1/admin/phd-students/promote",

    SAVE_SCHOLAR_SUBJECTS: "v1/admin/phd-students/save-scholar-subjects",
    LIST_SCHOLAR_SUBJECTS: "v1/admin/phd-students/list-scholar-subjects",
    DELETE_SCHOLAR_SUBJECTS: "v1/admin/phd-students/delete-scholar-subjects",
  },

  PHD_GUIDES: {
    CREATE_GUIDE: "v1/admin/phd-guides/create",
    LIST_GUIDES: "v1/admin/phd-guides/list",
    COUNT_GUIDES: "v1/admin/phd-guides/count-guides",
    REMOVE_GUIDE: "v1/admin/phd-guides/delete",
    UPDATE_GUIDE: "v1/admin/phd-guides/update",

    EMPLOYEE_NEW_CODE: "v1/admin/phd-guides/new-emp-code",

    LIST_GUIDES_SCHOLARS: "v1/admin/phd-guides/list-guide-scholars",
    ALLOCATE_SCHOLAR: "v1/admin/phd-guides/allocate-scholar",
    DELETE_GUIDE_SCHOLAR: "v1/admin/phd-guides/delete-guide-scholar",
  },
  ALUMNI_STUDENTS: {
    CREATE_STUDENT: "v1/admin/alumni/alumni-students/save",
    LIST_STUDENTS: "v1/admin/alumni/alumni-students/list",
    DELETE_STUDENTS: "v1/admin/alumni/alumni-students/delete",

    IMPORT_ALUMNI_MARKS: "v1/admin/alumni/alumni-students/import-alumni-marks",
    UPDATE_ALUMNI_MARKS: "v1/admin/alumni/alumni-students/update-alumni-marks",
    MARKSHEET_BY_REGNO: "v1/admin/alumni/alumni-students/marksheet",
  },
  SUBJECTS: {
    CREATE_NATURE: "v1/admin/subjects/nature/create",
    LIST_NATURE: "v1/admin/subjects/nature/list",
    DELETE_NATURE: "v1/admin/subjects/nature/delete",
    UPDATE_NATURE: "v1/admin/subjects/nature/update",

    CREATE_SUBJECT: "v1/admin/subjects/sub/create",
    LIST_SUBJECT: "v1/admin/subjects/sub/list",
    DELETE_SUBJECT: "v1/admin/subjects/sub/delete",
    UPDATE_SUBJECT: "v1/admin/subjects/sub/update",
    IMPORT_SUBJECT: "v1/admin/subjects/sub/import-subjects",

    UPDATE_SUBJECT_TO_BATCH: "v1/admin/subjects/sub/update-subject-to-batch",
    LIST_SUBJECTS_IN_BATCH: "v1/admin/subjects/sub/subjects-in-batch",
    UPDATE_SUBJECT_TO_SEMESTER:
      "v1/admin/subjects/sub/update-subject-to-semester",
    LIST_SUBJECTS_IN_SEMESTER: "v1/admin/subjects/sub/subjects-in-semester",
    DELETE_SUBJECT_IN_SEMESTER:
      "v1/admin/subjects/sub/delete-subject-in-semester",
    LIST_ARREAR_SUBJECTS_IN_MARKS:
      "v1/admin/subjects/sub/arrear-subjects-in-marks",
    DELETE_SUBJECT_IN_BATCH: "v1/admin/subjects/sub/delete-subject-in-batch",

    ALLOCATED_SUBJECTS: "v1/admin/subjects/sub/allocated-subjects",
    ALLOCATED_SUBJECTS_BY_ATTEMPT:
      "v1/admin/subjects/sub/allocated-subjects-by-attempt",

    //fee

    LIST_REVALUATION_FEE: "v1/admin/subjects/fee/list-revaluation-fee",
    ASSIGN_REVALUATION_FEE: "v1/admin/subjects/fee/assign-revaluation-fee",

    LIST_VALUATION_FEE: "v1/admin/subjects/fee/list-valuation-fee",
    ASSIGN_VALUATION_FEE: "v1/admin/subjects/fee/assign-valuation-fee",
    //phd
    CREATE_PHD_SUBJECT: "v1/admin/subjects/sub/create-phd-subject",
    LIST_PHD_SUBJECTS: "v1/admin/subjects/sub/list-phd-subjects",
    DELETE_PHD_SUBJECT: "v1/admin/subjects/sub/delete-phd-subject",
    UPDATE_PHD_SUBJECT: "v1/admin/subjects/sub/update-phd-subject",

    LIST_PHD_SUBJECTS_FOR_ALLOCATION:
      "v1/admin/subjects/sub/list-phd-subjects-for-allocation",

    ALLOCATED_PHD_SUBJECTS: "v1/admin/subjects/sub/allocated-phd-subjects",
  },
  EXAMS: {
    CREATE_EXAM_MONTH: "v1/admin/exam/month/create",
    LIST_EXAM_MONTH: "v1/admin/exam/month/list",
    DELETE_EXAM_MONTH: "v1/admin/exam/month/delete",
    UPDATE_EXAM_MONTH: "v1/admin/exam/month/update",

    LIST_NOMINAL_ROLL: "v1/admin/exam/nominal/list",

    UPDATE_EXAM_DATE: "v1/admin/exam/dates/update-date",
    UPDATE_CLG_FEE_DATE: "v1/admin/exam/dates/update-clg-fee-date",
    UPDATE_REVALUATION_DATE: "v1/admin/exam/dates/update-revaluation-date",
    LIST_EXAM_DATES: "v1/admin/exam/dates/list-dates",
    LIST_CLG_FEE_DATES: "v1/admin/exam/dates/list-clg-fee-dates",
    LIST_REVALUATION_DATES: "v1/admin/exam/dates/list-revaluation-dates",

    UPDATE_EXAM_FINE_CATEGORY: "v1/admin/exam/dates/update-category",
    UPDATE_REVALUATION_FEE_CATEGORY:
      "v1/admin/exam/dates/update-revaluation-category",

    CHECK_INTERNAL_MARKS_ENTERED: "v1/admin/exam/marks/check-internal-filled",

    SAVE_INTERNAL_MARKS: "v1/admin/exam/marks/save-internal-marks",
    SAVE_ARREAR_INTERNAL_MARKS:
      "v1/admin/exam/marks/save-arrear-internal-marks",
    GET_INTERNAL_MARKS: "v1/admin/exam/marks/get-internal-marks",
    GET_MARKSHEET_MARKS: "v1/admin/exam/marks/get-mark-sheet-marks",

    REVALUATION_STUDENTS_LIST:
      "v1/admin/exam/revaluation/revaluation-students-list",
    REVALUATION_SUBJECTS_COUNT:
      "v1/admin/exam/revaluation/revaluation-subjects-count",
    REVALUATION_SUBJECTS_LIST:
      "v1/admin/exam/revaluation/revaluation-subjects-list",
    SAVE_REVALUATION_MARKS: "v1/admin/exam/revaluation/save-revaluation-marks",
    SAVE_REVALUATION_MARKS_BY_DUMMYNO:
      "v1/admin/exam/revaluation/save-revaluation-marks-by-dummyno",
    GET_RESULT_MARKS: "v1/admin/exam/result/get-result-marks",
    PUBLISH_RESULT: "v1/admin/exam/result/publish-result",
    LIST_ARREARS: "v1/admin/exam/result/arrears-list",

    TERM_WISE_INTERNAL_MARKS: "v1/admin/exam/marks/term-internal-marks",
    UPDATE_MARKS: "v1/admin/exam/marks/update-marks",
    UPDATE_MARKS_GRADE: "v1/admin/exam/marks/update-marks-grade",
    UPDATE_SUMCG: "v1/admin/exam/marks/update-sumcg",
    UPDATE_CGP: "v1/admin/exam/marks/update-cgp",
    UPDATE_CGPA: "v1/admin/exam/marks/update-cgpa",
    UPDATE_CLASS: "v1/admin/exam/marks/update-class",
    SAVE_EXTERNAL_MARKS: "v1/admin/exam/marks/save-external-marks",
    SAVE_GRACE_MARKS: "v1/admin/exam/marks/save-grace-marks",

    IMPORT_MARKS: "v1/admin/exam/markimport/import-marks",
    IMPORT_ARREAR_MARKS: "v1/admin/exam/markimport/import-arrear-marks",
    IMPORT_LOGS: "v1/admin/exam/markimport/mark-import-logs",
    VIEW_LOGS: "v1/admin/exam/markimport/mark-import-log",

    CHECK_MARKS_ENTERED: "v1/admin/exam/grade/check-marks-entered",
    CHECK_GRADE_GENERATED: "v1/admin/exam/grade/check-grade-generated",
    CHECK_CGPA_GENERATED: "v1/admin/exam/grade/check-cgpa-generated",
    COUNT_OLD_BPES: "v1/admin/exam/grade/count-old-bpes-marks",
    OLD_BPES_CGPA_UPDATE: "v1/admin/exam/grade/old-bpes-cgpa-update",
    OLD_BPES_CLASS_UPDATE: "v1/admin/exam/grade/old-bpes-class-update",

    IMPORT_EXTERNAL_DUMMY_NO: "v1/admin/exam/dummyno/import",
    LIST_EXTERNAL_DUMMY_NOS: "v1/admin/exam/dummyno/list-dummy-nos",
    UPDATE_EXTERNAL_MARKS_BY_DUMMYNO: "v1/admin/exam/dummyno/save-marks",
    LIST_SCORING_HISTORY: "v1/admin/exam/dummyno/scoring-history",
    LIST_MARKS_BY_DUMMYNO: "v1/admin/exam/dummyno/marks-by-dummyno",
    UPDATE_MARKS_BY_DUMMYNO: "v1/admin/exam/dummyno/update-marks",

    LIST_DUMMY_NO_STUDENTS: "v1/admin/exam/dummyno/list-dummy-no-students",
    LIST_DUMMY_NO_PHD_STUDENTS:
      "v1/admin/exam/dummyno/list-dummy-no-phd-students",
    CHECK_DUMMY_NO: "v1/admin/exam/dummyno/check-dummy-no",
    GENERATE_DUMMY_NO: "v1/admin/exam/dummyno/generate-dummy-no",
    DUMMY_NO_LIST: "v1/admin/exam/dummyno/dummy-no-list",
    BUNDLE_NO_COUNT: "v1/admin/exam/dummyno/bundle-no-count",
    BUNDLE_NO_LIST: "v1/admin/exam/dummyno/bundle-no-list",

    CHECK_ATTENDANCE_ENTERED:
      "v1/admin/exam/attendance/check-attendance-entered",
    EXAM_ATTENDANCE_STUDENTS: "v1/admin/exam/attendance/attendance-students",
    SAVE_EXAM_ATTENDANCE: "v1/admin/exam/attendance/save-exam-attendance",
    LIST_EXAM_ATTENDANCE: "v1/admin/exam/attendance/list-exam-attendance",

    LIST_BACKLOG_SUBJECTS: "v1/admin/exam/backlog/backlog-subjects-in-marks",
    COUNT_BACKLOG_SUBJECTS: "v1/admin/exam/backlog/count-subjects-in-marks",
    ALLOCATE_BACKLOG_SUBJECTS:
      "v1/admin/exam/backlog/allocate-backlog-subjects",
    ASSIGN_BACKLOG_INT_MARKS: "v1/admin/exam/backlog/assign-backlog-int-marks",
    UPDATE_STUDENT_SUPPLEMENTARY_STATUS:
      "v1/admin/exam/backlog/update-supplementary-status",

    //Reports
    PASSOUT_PERCENTAGE: "v1/admin/exam/reports/passout-percentage",
    VALUATION_REPORT: "v1/admin/exam/reports/valuation-report",
  },
  PHD_EXAMS: {
    UPDATE_TASK_STATUS: "v1/admin/phd-exam/activities/update-task-status",

    LIST_PHD_EXAM_MONTHS: "v1/admin/phd-exam/months/list",
    CREATE_PHD_EXAM_MONTH: "v1/admin/phd-exam/months/create",
    DELETE_PHD_EXAM_MONTH: "v1/admin/phd-exam/months/delete",
    UPDATE_PHD_EXAM_MONTH: "v1/admin/phd-exam/months/update",
  },
  PAYMENTS: {
    CREATE_GATEWAY: "v1/admin/payments/gateway/create",
    LIST_GATEWAY: "v1/admin/payments/gateway/list",
    DELETE_GATEWAY: "v1/admin/payments/gateway/delete",
    UPDATE_GATEWAY: "v1/admin/payments/gateway/update",

    CREATE_SUBACCOUNT: "v1/admin/payments/subaccount/create",
    LIST_SUBACCOUNT: "v1/admin/payments/subaccount/list",
    DELETE_SUBACCOUNT: "v1/admin/payments/subaccount/delete",
    UPDATE_SUBACCOUNT: "v1/admin/payments/subaccount/update",

    CREATE_CATEGORY: "v1/admin/payments/category/create",
    CREATE_COLLEGE_CATEGORY: "v1/admin/payments/category/create-clg-category",
    LIST_CATEGORY: "v1/admin/payments/category/list",
    LIST_COLLEGE_CATEGORY: "v1/admin/payments/category/college-cat-list",
    DELETE_CATEGORY: "v1/admin/payments/category/delete",
    DELETE_COLLEGE_CATEGORY: "v1/admin/payments/category/delete-clg-category",
    UPDATE_CATEGORY: "v1/admin/payments/category/update",
    UPDATE_COLLEGE_CATEGORY: "v1/admin/payments/category/update-clg-category",

    ASSIGN_FEES: "v1/admin/payments/feeassign/save-fees",
    ASSIGN_COLLEGE_FEES: "v1/admin/payments/feeassign/save-college-fees",
    DELETE_ASSIGNED_FEES: "v1/admin/payments/feeassign/delete-fees",
    DELETE_COLLEGE_FEES: "v1/admin/payments/feeassign/delete-college-fees",

    REPORTS_DAILY_LIST: "v1/admin/payments/reports/list",
    REPORTS_COLLEGE_FEE: "v1/admin/payments/reports/list-college-fee",
    STUDENT_ORDERS: "v1/admin/payments/reports/student-orders",
    PHD_STUDENT_ORDERS: "v1/admin/payments/reports/phd-student-orders",

    LIST_PAYMENT_PAID: "v1/admin/payments/pay/payment-paid",
    LIST_PAYMENTS_TO_BE_PAY: "v1/admin/payments/pay/list-payments",
    CLG_PAYMENT_LINK: "v1/admin/payments/pay/clg-payment-link",

    LIST_PHD_YEARLY_FEES: "v1/admin/payments/fees/list-yearly-fees",
    CREATE_PHD_YEARLY_FEE: "v1/admin/payments/fees/create-yearly-fee",
    UPDATE_PHD_YEARLY_FEE: "v1/admin/payments/fees/update-yearly-fee",
    DELETE_PHD_YEARLY_FEE: "v1/admin/payments/fees/delete-yearly-fee",

    LIST_REQUEST_TYPES: "v1/admin/payments/fees/list-request-types",
    CREATE_REQUEST_TYPE: "v1/admin/payments/fees/create-request-type",
    UPDATE_REQUEST_TYPE: "v1/admin/payments/fees/update-request-type",
    DELETE_REQUEST_TYPE: "v1/admin/payments/fees/delete-request-type",
  },
  CRON: {
    TRACK_ORDER: "cron/track_orders",
    TRACK_ORDER_PHD: "cron/track_orders_phd",
  },
  QUESTION: {
    VERIFY_QSN_SUBJECT_CODE:
      "v1/admin/question/questionpreparation/verify-subject-code",
    SAVE_QUESTION_JSON:
      "v1/admin/question/questionpreparation/save-question-json",
    UPDATE_QUESTION_JSON:
      "v1/admin/question/questionpreparation/update-question-json",
    QUESTION_LIST: "v1/admin/question/questionpreparation/question-list",
    GET_QSN_JSON: "v1/admin/question/questionpreparation/get-qsn-json",
    UPDATE_APPROVAL_STATUS:
      "v1/admin/question/questionpreparation/update-approval-status",
    UPDATE_RELEASE_DATE:
      "v1/admin/question/questionpreparation/update-release-date",
    PUBLISH_QP1: "v1/admin/question/questionpreparation/publish-qp1",
    GET_QSN_PAPER: "v1/admin/question/questionpreparation/get-qsn-paper",
    EXAM_ATTENDANCE_SUBJECTS:
      "v1/admin/question/questionpreparation/exam-attendance-subjects",
  },

  UTILITIES: {
    LIST_STUDENT_CERTIFICATES: "v1/admin/util/stucert/list-all",
    GET_STUDENT_CERTIFICATE: "v1/admin/util/stucert/get",
    UPDATE_STUDENT_CERTIFICATE: "v1/admin/util/stucert/update",
    CREATE_STUDENT_CERTIFICATE: "v1/admin/util/stucert/save",
  },
};
